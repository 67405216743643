<template>
  <div>
    <lz-form :form="form" :col="3" ref="lzForm"></lz-form>
    <lz-sticky>
      <el-button type="primary" @click="handleSubmit">确 定</el-button>
    </lz-sticky>
  </div>
</template>
<script>
export default {
  data() {
    return {
      id: '',
      form: [
        { fieldName: 'content', type: 'editor', name: '详细信息', value: '', required: false, width: '100%' },
        // { fieldName: 'menu_id', type: 'text', name: 'menu_id', value: '', hide: true },
      ],
    };
  },
  mounted() {
    this.id = this.$route.query.id;
    this.init();
  },
  methods: {
    async init() {
      const response = await this.$baseHttp.get(`/auth-article/detail?menu_id=${this.id}`);
      if (!response) return;
      this.$baseTitle(`${response.data.title}-${this.$route.meta.title}`);
      this.$nextTick(() => {
        this.$refs.lzForm.setValue({
          data: response.data || {},
        });
      });
    },
    async handleSubmit() {
      const params = this.$refs.lzForm.getValue();
      if (!params) return;
      params.menu_id = this.id;
      const response = await this.$baseHttp.post('/auth-article/save', params);
      if (!response) return;
      this.$message.success('操作成功');
    },
  },
};
</script>
<style lang="sss" scoped></style>
